.appLoader {
    background-color: white;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999999999;
}
 
.appLoader img {
    position: absolute;
}
.loaderDotContainer {
    margin-left: -67px;
    margin-top: -67px;
}
.loaderDot {
    offset-path: path('M16.7,66.7C7.5,66.7,0,74.1,0,83.3S7.5,100,16.7,100s16.7-7.5,16.7-16.7c0,0,0-50,0-50C33.3,14.9,48.3,0,66.7,0S100,14.9,100,33.3c0,18.4-14.9,33.3-33.3,33.3C66.7,66.7,16.7,66.7,16.7,66.7z');
    animation: sweep 3s infinite linear;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 2px solid white;
    box-shadow: 0 0 5px 1px gray;
}
.loaderDot:nth-child(1) {
    animation-delay: 0s;
    background-color: #5d2c8f;
}
.loaderDot:nth-child(2) {
    animation-delay: -1.8s;
    background-color: #f6983a;
}
.loaderDot:nth-child(3) {
    animation-delay: -2.8s;
    background-color: #f63a3a;
} 
 
@keyframes sweep {
    0% {
        offset-distance: 0;
        transform: scale(0.5);
   }
    25% {
        transform: scale(0.3);
   }
    50%, 75% {
        transform: scale(1);
   }
    100% {
        offset-distance: 100%;
        transform: scale(0.5);
   }
}
