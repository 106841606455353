.get_touch {
    background: linear-gradient(92.91deg, rgba(92, 45, 145, 0.9) 0.84%, rgba(169, 101, 214, 0.9) 97.64%);
    color: #fff;
    border-radius: 8px;
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 600;
    padding: 0px 45px;
    text-align: center;
    height: 46px;
    line-height: 46px;
    width: auto !important;
    margin-right: 1rem;
}

.text-danger {
  display: none !important;
}

.w-auto {
    width: 33% !important;
}

.form_section {
    background-image: url('../../assets/images/mens-health/form-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
}

.form_module {
    background: url('../../assets/images/mens-health/form-block-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    box-shadow: 0px 4px 10.8px 0px #00000040;
    border-radius: 15px;
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.radio-inputs-container p {
    color: #fff;
    font-size: 14px;
    line-height: normal;
}

.sent_enquiry {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;

    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    width: 100%;
    height: 46px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Orange-Gradient, linear-gradient(91deg, #f7941d 58.96%, rgba(255, 173, 75, 0.86) 98.25%));
}

.desktop_sent_enquiry {
    display: block;
}

.mobile_sent_enquiry {
    display: none;
}

button:disabled,
button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(1);
}


.form_module label {
    font-family: 'Maven Pro';
    font-size: 20px;
    font-weight: 600;
    line-height: 23.5px;
    text-align: left;
    color: #4C4B4B;
    margin-bottom: 1.5rem;
}

.form_module input,
.form_module input::placeholder {
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 500;
    line-height: 21.15px;
    text-align: left;
    padding: 12px 15px;
    margin-bottom: 20px;
    color: #82817F;
}

.form_module input::placeholder {
    padding: 0;
}

.purple-text {
    color: #60308D !important;
}

.grey-text {
    color: #808080 !important;
}

.yellow-text {
    color: #F7941D !important;
}


.purple-bg {
    background-color: #5C2D91;
}

.yellow-bg {
    background-color: #F79321;
}

.banner-content h3 {
    font-family: 'Maven Pro';
    font-size: 25px;
    font-weight: 800;
    line-height: 43.2px;
    text-align: center;
    color: #FF8B00;
}

.banner-content h4 {
    font-family: 'Maven Pro';
    font-size: 25px;
    font-weight: 800;
    line-height: 43.2px;
    text-align: center;
    color: #5C2D91;
}

.banner-content h5 {
    font-size: 20px;
}

.section-heading {
    font-family: 'Maven Pro';
    font-size: 30px;
    font-weight: 800;
    line-height: 58.28px;
    text-align: center;
    margin-top: 4rem;
    color: #5C2D91;
    text-transform: uppercase;
}

.section-heading span {
    color: #FF8B00;
}

.friendship-day-page p {
    font-family: 'Maven Pro';
    font-size: 20px;
    font-weight:600;
    line-height: 1.5;
    text-align: center;
    color: #4C4B4B;
    width: 80%;
    margin: 1rem auto 0;
}

@media (min-width: 1500px) {
    .top-space {
        padding-top: 130px;
    }
}

@media (max-width: 600px) {
    .section-heading {
        font-size: 20px;
        line-height: 1.4;
    }
    .w-auto {
        width: 60% !important;
    }
}