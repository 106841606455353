footer { 
	background:#6e6e6e;
	position: relative;
	font-weight: 300;
	float:left;
	width: 100%;
} 
footer h5{
	color:#fff;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	margin-top: 50px;
}


footer .subs-cribe h5{
	color:#fff;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0px;
	margin-top: 20px;
}

footer p{
	font-size: 13px;
	font-weight: 400;
}
footer p strong{
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	display: block;
	padding-bottom: 10px;
}
footer a, footer .footer-bottom-text a strong{
	color: #fff;
}
footer a:hover, footer .footer-bottom-text a:hover strong, footer ul li a.active{
	color: #f59637;
}
footer .footer-map-link a{
	font-weight: 700;
	text-decoration: underline;
}
footer .footer-column{
	display: flex;	
}
footer .footer-column.subs-cribe{
	display:block;	position:relative;
}
footer .footer-column.subs-cribe p{
	color:#fff;
}

footer .quick-links .footer-column ul{
	width: 40%;
}
footer .footer-column ul{
	min-width: 200px;
}

footer ul li{
	list-style: none;
}

footer ul li a{
	display: inline-block;
	padding: 7px 7px 7px 0px;
	font-size: 14px;
	position: relative;
}

footer ul li a:hover, footer ul li a.active{
	padding: 7px 7px 7px 8px;
}

footer ul li a:before{
	position: absolute;
	content:"";
	width: 2px;
	height: 0px;
	left: 0;
	background: #f59637;
	top: 10%;
	opacity: 0;
	transition: all .6s ease;
}

footer ul li a:hover:before, footer ul li a.active:before{
	height: 80%;
	opacity: 1;
	transition: all .6s ease;
}

footer .footer-bottom-text{
	padding: 20px 0px;
	font-size: 13px;
}

footer ul.footer-socials{
	margin-top: 30px;
}

footer ul.footer-socials li {
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	transition: all .3s ease;
}

footer ul.footer-socials li a {
	background-color: rgba(255, 255, 255, 0);
	border-radius: 100%;
	height: 40px;
	width: 40px;
	font-size: 20px;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding:0px;
	transition: all .35s ease;
}

footer ul.footer-socials li a:before{
	display: none;
}

footer ul.footer-socials li a:hover{
	background: #fff;
	color: var(--color-dark-blue);
	transition: all .35s ease;
}

footer .divider{
	background: rgba(255,255,255,0.2);
	width: 100%;
	height: 1px;
	clear: both;
	margin-top: 50px;
}

a.whatsappp{
	position: fixed;
	z-index: 999;
	right: 5px;
	bottom: 10px;
}

.mobile-socials{
	display: none;
	position: fixed;
	top: 30%;
	right: -30px;
	z-index: 999;
	transition: all .35s ease;
}
.scroll .mobile-socials{
	right: 0px;
	transition: all .35s ease;
}
.mobile-socials.active{
	right: 30px;
	transition: all .35s ease;
}
.mobile-socials .mobile-share-icon{
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: #ff9a1f;
	border-radius: 5px 0px 0px 5px;
	cursor: pointer;
}
.mobile-socials ul{
	position: fixed;
	top: 30%;
	right: -50px;
	transition: all .35s ease;
}
.mobile-socials.active ul{
	right: 0px;
	border:1px solid rgba(0,0,0,0.2);
	border-right: none;
	transition: all .35s ease;
}

.mobile-socials ul li{
	list-style: none;
}

.mobile-socials ul li a{
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	color: #602f98;
	transition: all .35s ease;
}

.mobile-socials ul li a:hover{
	color: #fff;
	transition: all .35s ease;
}

.mobile-socials ul li a.facebook:hover{
	background: #4267B2;
	transition: all .35s ease;
}

.mobile-socials ul li a.twitter:hover{
	background: #00ACEE;
	transition: all .35s ease;
}

.mobile-socials ul li a.youtube:hover{
	background: #FF0000;
	transition: all .35s ease;
}

.mobile-socials ul li a.linkedin:hover{
	background: #0077b5;
	transition: all .35s ease;
}

.mobile-footer{
	position: fixed;
	left: 0;
	width: 100%;
	bottom: -100px;
	z-index: 999;
	display: none;
	align-items: center;
	justify-content: space-between;
	background: #ff9a1f;
	transition: all .35s ease;
}

.scroll .mobile-footer{
	bottom: 0px;
	transition: all .35s ease;
}

.mobile-footer a{
	padding: 12px 0px 4px;
	width: 20%;
	border-right:1px solid rgba(255,255,255,0.3);
	text-align: center;
	transition: all .35s ease;
	background: #ff9a1f;
	color:#000;
	font-weight: 500;
	position: relative;
}

.mobile-footer a:hover .numb-circle{color:#fff;transition: all .35s ease;}
.numb-circle {
	position: absolute;
	top: 22px; 
	height: 20px;
	width: 100%;
	border-radius: 100%;
	color: #000; 
	font-size: 9px;
	line-height: 16px;
	left: 3px;
	text-align: center;transition: all .35s ease; 
  }
.mobile-footer a img {
	height: 22px;
	display: block;
	margin: 0 auto;
	margin-bottom: 0px;
	margin-bottom: 5px;transition: all .35s ease;
  }

  .mobile-footer a:hover img{
  filter: invert(100%);
  transition: all .35s ease;
  }

.mobile-footer a:last-child{
	border-right: none;
}
.mobile-footer a:hover{
	transition: all .35s ease;
	background: #602f98;
	color:#fff;
}
.mobile-footer a:hover span{ 
	color:#fff;
}

.tooltip-inner {
    color: #fff;
    background: #602f98;
    border-radius: 5px;
	opacity: 1;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #602f98;
}

@media (max-width:1200px) {	
	footer ul.footer-socials li a{
		height: 30px;
		width: 30px;
		font-size: 16px;
	}
}
@media (max-width:990px) {	
	footer ul.footer-socials{
		margin-top: 30px;
	}
}


@media (max-width:767px) {
	footer .footer-bottom-text{
		padding: 10px 0px;
		text-align: center !important;
	}
	footer .footer-column{
		display: block;
	}
	.mobile-socials{
		display: block;
	}
	a.whatsappp{
		display: none;
	}
	.mobile-footer{
		display: flex;
	}
	footer .quick-links .footer-column ul{
		width: 100%;
	}
	footer .footer-column ul{
		min-width: auto;
	}
}

