.orange-col-text {
    color: #FF8B00;
    font-family: Maven Pro;
    font-size: 40px;
    font-weight: 800;
    line-height: 43.2px;
    text-align: center;
    /* margin-top: 10rem; */
    padding-bottom: -0.7rem;
    margin-bottom: 1px;
}

.violet-col-text {
    color: #5C2D91;
    font-family: Maven Pro;
    font-size: 35px;
    font-weight: 700;
    line-height: -43.2px;
    text-align: center;

}

.violett-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    margin-top: 2rem;
    margin-left: 0.8rem;

}


.form_section {
    /* background-image: url('../../assets/images/independence-day/independence-banner-bg.jpg'); */
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
}

.g-0 {
    padding: 0;
}

.form_module {
    background: url('../../assets/images/mens-health/form-block-bg.png');
    background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    box-shadow: 0px 4px 10.8px 0px #00000040;
    border-radius: 15px;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.call-btn.sent_enquiry {
    font-size: 20px;
}

.sent_enquiry {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;

    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    width: 100%;
    height: 46px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Orange-Gradient, linear-gradient(91deg, #f7941d 58.96%, rgba(255, 173, 75, 0.86) 98.25%));
}

.desktop_sent_enquiry {
    display: block;
}

.mobile_sent_enquiry,
.mobile {
    display: none;
}

button:disabled,
button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(1);
}


.form_module label {
    font-family: 'Maven Pro';
    font-size: 20px;
    font-weight: 600;
    line-height: 23.5px;
    text-align: left;
    color: #4C4B4B;
    margin-bottom: 1.5rem;
}

.form_module input,
.form_module input::placeholder {
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 500;
    line-height: 21.15px;
    text-align: left;
    padding: 12px 15px;
    margin-bottom: 20px;
    color: #82817F;
}

.SeniorCitizenCard .name-number-inp,
.SeniorCitizenCard .submit-connect-form {
    width: 80% !important;
}

.form_module input::placeholder {
    padding: 0;
}

.Pn-text {
    font-family: 'Maven Pro';
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    margin: 1rem 0;
}

.bg-orange {
    background: linear-gradient(90.97deg, #F7941D 58.96%, rgba(255, 173, 75, 0.86) 98.25%);
    text-align: center;
    padding: 0.25rem 5rem 0rem 5rem;
    border-radius: 12px;
    /* width: 20rem; */
    margin-top: 0rem;
    border-color: transparent;
    margin-bottom: 3rem;
}

.calling {
    height: 2rem;
    margin-left: -1.5rem;
    margin-top: 0.3rem;
}

.main-container {
    display: flex;
    align-items: stretch;
    border-radius: 20px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: auto;
    margin-bottom: 3rem;

}

.image-container {
    flex: 1.3;
}

.couple-image {
    width: 100%;
    height: 430px;
    display: block;
    border-radius: 20px 0 0 20px;
}

.form-container {
    flex: 1;
    /* background: linear-gradient(to bottom, #ffffff, #5c289f); */
    text-align: center;
    border-radius: 0 20px 20px 0;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.icon-item p {
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 1rem;
    text-align: center;
    color: #5C2D91;
}


.form-head {
    font-size: 25px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;

}

.input-group {
    margin-bottom: 20px;
    width: 500px;

}

.text-input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 18px;
    background: #f6f6f6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

.recaptcha-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f6f6f6;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 20px auto;
}

.recaptcha-container label {
    margin-left: 10px;
    font-size: 18px;
    white-space: nowrap;
    flex-grow: 1;
}

.recaptchalogo {
    height: 50px;
    margin-left: 280px;
}

.req-button {
    width: 90%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 3px;
    margin-top: 14px;
    font-size: 18px;

}

.req-button:hover {
    background-color: #e68a00;
}

.width-fitcontent {
    width: fit-content;
    padding: 10px 3rem;
}

.container {
    /* padding: 20px 0; */
    text-align: center;
}

.row {
    margin: 0;
}

.text-container {
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.o-col,
.v-col {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 6px;
    display: flexbox;
}

.o-col {
    color: #F9A825;
}

.v-col {
    color: #5E35B1;
}



.benefits-container {
    background-color: #FFF3E0;
    padding: 50px;
    border-radius: 10px;
}

.discounts-container {
    /* background-color: #FFF3E0; */
    padding: 0;
}

.benefits-header {
    text-align: center;
    margin-bottom: 20px;
}

.benefits-title {
    color: #4C4B4B;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.2px;
    text-align: center;

}

.benefits-icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-left: 4rem;
    margin-right: 4rem;
}

.icon-item {
    text-align: center;
    margin: 0;
    flex: 1;
    min-width: 150px;
    padding: 1.5rem;
    gap: 3px;
}

.icon-item img {
    width: 60px;
    height: 70px;
    margin-bottom: 5px;
    object-fit: contain;
}

.yourSpanClass {
    display: flex;
    align-items: center;
    margin: 0 20px 20px 0;
}

.offers-discounts {
    background-color: #fff;
    padding: 15px 15px 15px 40px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* margin-top: 30px; */
    text-align: left;
    /* height: 18rem;
    width: 80%; */
    margin-left: auto;
    margin-right: auto;
}

.OffersAndDiscount {
    /* height: 30px; */
    width: 30px;
}

.offers-title {
    font-weight: bold;
    color: #263238;
    margin-bottom: 15px;
    display: flex;
    margin-left: 1rem;
    margin-top: 1rem;
}

.orange-text {
    color: #F9A825;
}

.violet-txt {
    color: #5E35B1;
}

.offers-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.offers-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.offer-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}


.elder-care-logo1 {
    display: block;
    margin: 10px auto;
    max-width: 27rem;
    margin-left: 43rem;
    margin-top: -16rem;
}

.card-container {
    background: linear-gradient(180deg, #5C2D91 0%, #30164E 100%);
    color: #fff;
    padding: 50px 50px 25px 50px;
    border-radius: 10px;
    text-align: center;
}

.image-container {
    display: flex;
    justify-content: center;
}

.elder-care-logo {
    width: 600px;
    height: 450px;
    object-fit: fill;
    margin-left: 4rem;

}

.elder-care-ben {
    width: 630px;
    height: 435px;
    object-fit: fill;
    margin-left: -2rem;

}


.cta-container {
    text-align: center;
    padding: 20px;
}

.cta-container p {
    color: #fff;
    margin: 5px 0;
    font-family: 'Maven Pro', sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 30.55px;
    text-align: center;
}

.cta-container button {
    background-color: #F9A825;
    border: none;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: background-color 0.3s ease;
    font-family: 'Maven Pro', sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
}

.cta-container button:hover {
    background-color: #F57F17;
}

.w-col {
    color: #f5f3f7;
    font-weight: bold;
    font-size: 2rem;
    margin: 0 5px;
    display: flexbox;
}

.page-header span {
    color: #FF8B00;
}

.SeniorCitizenCard {
    padding-top: 70px;
}

.b-orange {
    margin-top: 3rem;
}

@media (min-width: 1400px) {
    .SeniorCitizenCard {
        padding-top: 140px;
    }
}

@media (max-device-width: 1025px) {
    .SeniorCitizenCard {
        padding-top: 130px;
    }

    .banner-img.img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


@media (max-device-width: 900px) {
    .benefits-icons {
        flex-direction: row;
        align-items: center;
        display: flex;
    }

    .icon-item {
        min-width: 33.33%;
        padding: 0;
    }

    .card-container .image-container {
        flex-direction: column;
    }

    .card-container img {
        margin-bottom: 10px;
    }
}


@media (max-device-width: 769px) {
    .benefits-icons {
        flex-direction: row;
        align-items: center;
        display: flex;
    }

    .icon-item {
        min-width: 33.33%;
        padding: 0;
    }

    .card-container .image-container {
        flex-direction: column;
    }

    .card-container img {
        margin-bottom: 10px;
    }
}

@media (max-device-width: 700px) {
    .SeniorCitizenCard {
        padding-top: 70px;
    }

    .orange-col-text,
    .violet-col-text,
    .o-col,
    .v-col {
        font-size: 25px;
    }

    .benefits-icons {
        margin-left: 0;
        margin-right: 0;
    }

    .icon-item {
        margin: 0;
    }
}


@media (max-device-width: 600px) {
    .get-callback-heading{
        padding-top: 1.5rem;
    }

    .call-btn.sent_enquiry{
        height: 65px;
        font-size: 16px;
    }

    .offers-discounts {
        padding: 15px;
    }

    .icon-item {
        min-width: 100%;
        padding: 0;
    }

    .SeniorCitizenCard {
        padding-top: 70px;
    }

    .orange-col-text,
    .violet-col-text,
    .o-col,
    .v-col,
    .w-col {
        font-size: 25px;
    }

    .card-container {
        padding: 30px;
    }

    .benefits-icons {
        margin-left: 0;
        margin-right: 0;
    }

    .icon-item {
        margin: 0;
    }
}